import React from 'react';
import ReactDOM from 'react-dom';

// order of imports matters here - styles need to be imported BEFORE App.tsx is imported. As lib-ui-react-common comes
// with it's own set of styles, that are overwriting bootstrap styles, they need to be imported AFTER import of bootstrap css.
import '../../theme/custom-bootstrap-theme-light.scss';
import 'font-awesome/css/font-awesome.css';

import {OktaError} from './okta';

ReactDOM.render(<OktaError />, document.getElementById('root-okta-error'));
